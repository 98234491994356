import type { APIFilter } from '../api'
import type { ProcurementItemPurchaseResponse } from './procurement-item-purchase-service'
import { mapProcurementItemPurchaseResponseToProcurementItemPurchase } from './procurement-item-purchase-service'
import type { RawSku } from '~~/types/raw-sku'
import type { Page } from '~~/types/api-common'
import type { ProcurementItem } from '~~/types/procurement'
import { ProcurementItemStatus } from '~~/types/procurement'
import { RawSku } from './raw-sku-service'

const FILTER_OLD_SL_TAGS = ['bbc', 'lojas', 'folhas', 'pedra']

export const useProcurementItemService = () => {
    const fetchProcurementItems = async ({
        with_inward_lot= true, page = 1, size = 100, projection = [], since, until, filter, empty,
    }: FetchProcurementItemsOptions) => {
        const params: Record<string, any> = {
            page,
            size,
            empty,
            filter: createAPIFilter(filter),
            projection: [
                ...projection, 'maturation_type', 'quality_type', 'raw_product', 'purchases', 'raw_product_provider',
            ],
            with_inward_lot,
        }
        if (since) {
            params.since = since
        }
        if (until) {
            params.until = until
        }

        const data = await api<ListProcurementItemResponse>(
            '/procurement_item/',
            { params },
        )

        const mapResponseItemsToModel = (item: ProcurementItemResponse): ProcurementItem => {
            let tags = [
                ...(item.tags || []),
                item?.quality_type?.name ? `quality_${item?.quality_type?.name}` : null,
                item?.maturation_type?.name ? `maturation_${item?.maturation_type?.name}` : null,
            ].filter(tag => Boolean(tag) && tag !== 'DARK_LAUNCH')

            for (const tag of tags) {
                if (tag === 'Incremental' && tags.includes('JustInTime')) {
                    tags = tags.filter(tag => tag !== 'JustInTime')
                }
            }
            
            return {
                id: item.id_procurement_item,
                name: String(item.raw_product?.product_name),
                wholesale: {
                    unit: item.raw_product?.quantity_wholesale_unit,
                    quantity: item.raw_product?.quantity_wholesale,
                },
                buyEvents: item.purchases.map(mapProcurementItemPurchaseResponseToProcurementItemPurchase),
                notFound: item.status === ProcurementItemStatus.NotFound,
                image: item.raw_product?.image,
                tags,
                boxes: (() => {
                    const { total_units, total_weight } = item
                    return Math.ceil((item.raw_product?.quantity_wholesale_unit === 'KG' ? total_weight : total_units) / item.raw_product?.quantity_wholesale)
                })(),
                units: item.total_units,
                weight: item.total_weight,
                quantity: (() => {
                    if (item.raw_product?.quantity_wholesale_unit === 'KG') {
                        return item.total_weight
                    }
                    return item.total_units
                })(),
                averageUnitWeight: item.raw_product?.average_unit_weight,
                defaultProvider: item?.raw_product_provider?.id_provider,
                status: item.status,
                updatedAt: item.updated_at,
                productId: item.id_raw_product,
                qualityTypeId: item.id_quality_type,
                maturationTypeId: item.id_maturation_type,
                raw_sku: item.raw_sku,
                inwardLot: item.raw_sku?.inward_lot,
            }
        }

        return data.items.map(mapResponseItemsToModel).filter(item => !FILTER_OLD_SL_TAGS.some(tag => item.tags.includes(tag)))
    }

    const createProcurementItem = async (procurementItem: ProcurementItemCreate) => {
        const data = await api.post<CreateProcurementItemResponse>(
            '/procurement_item/',
            procurementItem,
        )
        return data
    }

    const updateProcurementItem = async (id: string, procurementItem: ProcurementItemUpdate) => {
        const data = await api.patch<ProcurementItemResponse>(
            `/procurement_item/${id}`,
            procurementItem,
        )
        return data
    }

    return { fetchProcurementItems, createProcurementItem, updateProcurementItem }
}

type ListProcurementItemResponse = Page<ProcurementItemResponse>
type CreateProcurementItemResponse = Array<ProcurementItemResponse>

interface ProcurementItemResponse {
    id_quality_type: string
    total_weight_in_stock?: any
    id_maturation_type: string
    total_boxes_in_stock?: any
    created_at: string
    id_provider: string
    updated_at: string
    total_units: number
    deleted_at?: any
    total_weight: number
    id_procurement_item: string
    total_boxes: number
    id_shopper: string
    total_units_in_stock?: any
    id_raw_product: string
    quality_type?: QualityTypeResponse
    maturation_type?: MaturationTypeResponse
    raw_product?: RawProductResponse
    purchases: ProcurementItemPurchaseResponse[]
    tags?: string[]
    raw_product_provider?: RawProductProviderResponse
    status: ProcurementItemStatus
    raw_sku: RawSku
}

interface RawProductResponse {
    updated_at?: string | null
    id_raw_product: string
    catalog_raw_product_id: number
    quantity_wholesale: number
    quantity_wholesale_unit: string
    ncm: string
    created_at?: string | null
    deleted_at?: any | null
    product_name: string
    average_unit_weight: number
    image?: any
    id_product_family: string
}

interface MaturationTypeResponse {
    updated_at: string
    name: string
    id_maturation_type: string
    created_at: string
    deleted_at?: any
}

interface QualityTypeResponse {
    name: string
    created_at: string
    deleted_at?: any
    id_quality_type: string
    updated_at: string
}

interface ProcurementItemCreate {
    id_raw_product: string
    // id_quality_type: string | null
    // id_maturation_type: string | null
    id_shopper: string | null
    id_location: string | null
    total_units: number | null
    total_weight: number | null
    tags: Array<string>
}
interface RawProductProviderResponse {
    created_at: string
    deleted_at?: any
    id_raw_product: string
    id_quality_type: string
    status: string
    updated_at: string
    id_raw_product_provider: string
    id_provider: string
    id_location: string
    default_provider: boolean
}

type ProcurementItemUpdate = Partial<
ProcurementItemCreate & {
    status: ProcurementItemStatus
}
>

export interface FetchProcurementItemsOptions {
    filter?: APIFilter
    page?: number
    size?: number
    projection?: string[]
    since?: string
    until?: string
    empty?: boolean
    with_inward_lot?: boolean
}
