import type { BuyEvent } from './buy-event'
import type { InwardLot, RawSku } from './raw-sku'

export interface ProcurementItem {
    id: string
    name: string
    units: number
    weight: number
    boxes: number
    wholesale: {
        unit: string
        quantity: number
    }
    image: string
    tags: string[]
    buyEvents: BuyEvent[]
    notFound: boolean
    quantity: number
    defaultProvider?: string
    status: ProcurementItemStatus
    updatedAt: string
    averageUnitWeight: number
    productId: string
    qualityTypeId: string
    maturationTypeId: string
    raw_sku: RawSku
    inwardLot?: InwardLot
}

export enum ProcurementItemStatus {
    NotFound = 'Not Found',
    PartiallyBought = 'Partially Bought',
    Bought = 'Bought',
}
