import { acceptHMRUpdate, defineStore } from 'pinia'
import { ProcurementItemStatus } from '../types/procurement'
import type { FetchProcurementItemsOptions } from './services/procurement-item-service'
import { useProcurementItemService } from './services/procurement-item-service'
import { useProcurementItemPurchaseService } from './services/procurement-item-purchase-service'
import type { ProcurementItem } from '~~/types/procurement'
import type { Filter } from '~~/types/filter'
import type { BuyEvent } from '~~/types/buy-event'
import { MeasureType } from '~~/types/buy-event'

const sumPurchasedItemQuantity = (item: ProcurementItem) => {
    const getBuyEventQuantity = (buyEvent: BuyEvent) => {
        if (buyEvent.measureType === MeasureType.Box) {
            return buyEvent.quantity * buyEvent.wholesaleQuantity
        }
        return buyEvent.quantity
    }
    const buyEvents = item.buyEvents
    const quantity = buyEvents.reduce((acc, buyEvent) => acc + getBuyEventQuantity(buyEvent), 0)
    return quantity
}

export const useProcurementStore = defineStore('procurement', {
    persist: false,
    state: () => ({
        procurementItems: null,
        filterBy: 'all',
        searchQuery: '',
        referenceDate: new Date().toISOString().split('T')[0],
        tagFilterList: [],
        filterWithRupture: false,
        filterEmpty: true,
    } as ProcurementStoreState),
    getters: {
        getItemById: state => (id: string) => (state.procurementItems || [])
            .find(item => item.id === id),

        getItemCount(): number {
            return (this.getFilteredItems || []).length
        },

        getBoughtItemCount(): number {
            return (this.getFilteredItems || [])
                .filter(item => (item.buyEvents || []).length > 0)
                .length
        },

        getRuptureItemCount(): number {
            return (this.getFilteredItems || [])
                .filter(item => (item.buyEvents || []).length > 0 && sumPurchasedItemQuantity(item) < item.quantity)
                .length
        },

        getNotFoundItemCount(): number {
            return (this.getFilteredItems || [])
                .filter(item => item.notFound === true && item.buyEvents.length === 0)
                .length
        },

        getPurchasedQuantitySumById: state => (id: string) => {
            const item = (state.procurementItems || [])
                .find(item => item.id === id)
            if (!item) { return 0 }
            return sumPurchasedItemQuantity(item)
        },

        getItemTags: (state) => {
            const { formatTag } = useTags()
            const tags = new Set<string>()
            ;(state.procurementItems || [])
                .forEach(item => item.tags.forEach(tag => tags.add(tag)))
            return Array.from(tags).map(formatTag)
        },

        getFilteredItems(state): ProcurementItem[] {
            const { formatTag } = useTags()
            const { fuzzyMatch } = useSearch()

            return (state.procurementItems || [])
            // Matches the item name and the labels from the tags
            // as well as any applied tag filters
                .filter((item) => {
                    const tagsLabel = item.tags.map(tag => formatTag(tag).label).join(' ')
                    const matchesSearch = !state.searchQuery
                    || fuzzyMatch(`${item.name} ${tagsLabel}`, state.searchQuery)
                    const matchesTagFilter = !state.tagFilterList.length
                    || state.tagFilterList.every(tag => tagsLabel.includes(tag))
                    const matchesRuptureFilter = !state.filterWithRupture
                    || (item.buyEvents.length > 0 && this.getPurchasedQuantitySumById(item.id) < item.quantity)

                    return matchesSearch && matchesTagFilter && matchesRuptureFilter
                })
                .sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.tags.map(formatTag).join(' ').localeCompare(b.tags.map(formatTag).join(' ')))
        },

    },
    actions: {
        async listProcurementItems({ page = 1, size = 1000, ...options }: FetchProcurementItemsOptions) {
            log('Listing procurement items')
            const { fetchProcurementItems } = useProcurementItemService()
            const items = await fetchProcurementItems({ page, size, empty: this.filterEmpty, ...options })
            this.procurementItems = items
        },

        async synchronizeProcurementItems({ page = 1, size = 1000, ...options }: FetchProcurementItemsOptions) {
            log('Synchronizing procurement items')
            const { fetchProcurementItems } = useProcurementItemService()
            const items = await fetchProcurementItems({ page, size, empty: this.filterEmpty, ...options })
            for (const item of items) {
                this.updateProcurementItem(item.id, item)
            }
        },

        setFilter(filterBy: Filter) {
            this.filterBy = filterBy
        },

        updateProcurementItem(id: string, item: Partial<ProcurementItem>) {
            const index = this.procurementItems.findIndex(_item => _item.id === id)
            if (index > -1) {
                this.procurementItems[index] = { ...this.procurementItems[index], ...item }
            } else {
                this.procurementItems.push({ ...item })
            }
        },

        async addProcurementItem(payload: { productId: string; maturationTypeId?: string; qualityTypeId?: string; shopperId: string; locationId: string; buyEvent: BuyEvent; referenceDate: string }) {
            const { createProcurementItem } = useProcurementItemService()

            const procurementItemPayload = {
                id_raw_product: payload.productId,
                id_quality_type: payload.qualityTypeId,
                id_maturation_type: payload.maturationTypeId,
                id_shopper: payload.shopperId,
                id_location: payload.locationId,
                total_weight: payload.buyEvent.measureType === MeasureType.Weight ? payload.buyEvent.quantity : 0,
                ...(payload.buyEvent.measureType === MeasureType.Weight ? { total_weight_ordered: payload.buyEvent.quantity } : {}),
                total_units: payload.buyEvent.measureType === MeasureType.Unit ? payload.buyEvent.quantity : 0,
                ...(payload.buyEvent.measureType === MeasureType.Unit ? { total_units_ordered: payload.buyEvent.quantity } : {}),
                tags: ['Canetada', payload.buyEvent.isJustInTime ? 'JustInTime' : null].filter(Boolean),
                to_be_purchased_at: new Date(payload.referenceDate).toISOString().split('T')[0],
            }

            return (await createProcurementItem(procurementItemPayload)).pop()
        },

        async updateProcurementItemStatus(id: string, status: ProcurementItemStatus) {
            const { updateProcurementItem } = useProcurementItemService()
            await updateProcurementItem(id, { status })
            this.updateProcurementItem(id, { status, notFound: status === ProcurementItemStatus.NotFound })
        },

        async addBuyEvent(procurementItemId: string, event: BuyEvent, updateItem?: ProcurementItem) {
            const { addProcurementItemPurchase } = useProcurementItemPurchaseService()
            const newEvent = await addProcurementItemPurchase({
                itemPurchase: event, procurementItemId,
            })

            if (updateItem) {
                this.updateProcurementItem(procurementItemId, {
                    buyEvents: [...updateItem.buyEvents, newEvent],
                })
            }

            return newEvent
        },

        async updateBuyEvent(item: ProcurementItem, buyEvent: BuyEvent) {
            const { updateProcurementItemPurchase } = useProcurementItemPurchaseService()
            const updatedEvent = await updateProcurementItemPurchase({
                itemPurchase: buyEvent, procurementItemId: item.id,
            })

            this.updateProcurementItem(item.id, {
                buyEvents: item.buyEvents
                    .map(_buyEvent => _buyEvent.id === buyEvent.id ? updatedEvent : _buyEvent),
            })
        },

        async deleteBuyEvent(item: ProcurementItem, buyEvent: BuyEvent) {
            const { deleteProcurementItemPurchase } = useProcurementItemPurchaseService()
            await deleteProcurementItemPurchase({
                itemPurchase: buyEvent,
            })

            this.updateProcurementItem(item.id, {
                buyEvents: item.buyEvents
                    .filter(_buyEvent => _buyEvent.id !== buyEvent.id),
            })
        },

    },
})

function log(...args: any[]) {
    if (__DEV__) { console.info('[Procurement Item Store]', ...args) }
}

interface ProcurementStoreState {
    procurementItems: null | ProcurementItem[]
    filterBy: Filter
    searchQuery: string
    referenceDate: string
    tagFilterList: string[]
    filterWithRupture: boolean
}

export interface ListProcurementItems {
    items: ProcurementItem[]
    page: number
    size: number
    total: number
}

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useProcurementStore, import.meta.hot)) }
